import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
      background-color: ${theme.colors.background};
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }

    input,
    textarea,
    button {
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }
  `,
);
