import { ComingSoon } from 'components/ComingSoon';
import { Copyright } from 'components/Copyright';
import { Features } from 'components/Features';
import { Intro } from 'components/Intro';
import { Layout } from 'components/Layout';
import { Navigation } from 'components/Navigation';
import { NavigationLink } from 'components/Navigation/types';
import { Pricing } from 'components/Pricing';
import { Register } from 'components/Register';
import { RegisterInput } from 'components/RegisterInput/RegisterInput';
import { lightTheme } from 'lib/theme';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

export const scrollToCallback = (ref: React.RefObject<HTMLDivElement>) => (
  e?: React.MouseEvent,
) => {
  e?.preventDefault();
  ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

export const focusInputCallback = (
  ref: React.RefObject<HTMLInputElement>,
) => () => {
  ref.current?.focus();
};

export const Home = () => {
  const pricingRef = React.createRef<HTMLDivElement>();
  const registerRef = React.createRef<HTMLDivElement>();
  const mailingListRef = React.createRef<HTMLInputElement>();

  const navigationLinks: NavigationLink[] = React.useMemo(
    () => [
      {
        title: 'Pricing',
        anchor: '#pricing',
        action: scrollToCallback(pricingRef),
      },
      {
        title: 'Register',
        anchor: '#register',
        action: scrollToCallback(registerRef),
      },
    ],
    [pricingRef.current, registerRef.current],
  );

  const focusInput = React.useCallback(focusInputCallback(mailingListRef), [
    mailingListRef,
  ]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sessions - Take the Headache out of Managing Your Clients’ Sessions
        </title>
        <link rel="canonical" href="https://getsessions.co" />
      </Helmet>
      <ThemeProvider theme={lightTheme}>
        <Layout>
          <Navigation links={navigationLinks} />
          <Intro />
          <Features />
          <ComingSoon />
          <Pricing ref={pricingRef} interestedAction={focusInput} />
          <Register ref={registerRef}>
            <RegisterInput ref={mailingListRef} />
          </Register>
          <Copyright />
        </Layout>
      </ThemeProvider>
    </>
  );
};
