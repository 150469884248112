import styled, { css } from 'styled-components';

export const Button = styled.button(
  ({ theme }) => css`
    appearance: none;
    font-size: ${theme.type.cta.fontSize}px;
    line-height: ${theme.type.cta.lineHeight}px;
    min-width: ${theme.grid * 20}px;
    padding-top: ${theme.grid / 2}px;
    padding-bottom: ${theme.grid / 2}px;
    padding-left: ${theme.grid}px;
    padding-right: ${theme.grid}px;
    border-radius: ${theme.grid}px;
    border: solid 1px ${theme.colors.copy};
    background-color: ${theme.colors.background};
    color: ${theme.colors.copy};
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    outline: 0;
    cursor: pointer;

    &:active {
      opacity: 0.9;
    }
  `,
);
