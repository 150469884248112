import styled, { css } from 'styled-components';

export const CopyrightOuterWrapper = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.background};
    padding-top: ${theme.grid * 3}px;
    padding-bottom: ${theme.grid * 3}px;
  `,
);

export const SmallPrint = styled.small(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.copy};
    font-size: ${theme.type.smallPrint.fontSize}px;
    line-height: ${theme.type.smallPrint.lineHeight}px;
    margin-top: ${theme.grid}px;
  `,
);
