import styled, { css } from 'styled-components';

export const FluidWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    padding-right: ${theme.grid * 4}px;
    padding-left: ${theme.grid * 4}px;
    max-width: ${theme.grid * 100}px;
  `,
);
