import { FluidWrapper } from 'components/FluidWrapper/layout';
import {
  Heading,
  IntroOuterWrapper,
  IntroSubheading,
} from 'components/Intro/layout';
import { Paragraph } from 'components/Paragraph';
import * as React from 'react';

export const Intro: React.FC = () => (
  <IntroOuterWrapper>
    <FluidWrapper>
      <Heading>Take the headacheout of managing your clients’ Sessions</Heading>
      <IntroSubheading>
        For entrepreneurs who sell their expertise as a service
      </IntroSubheading>
      <Paragraph>
        Sessions is for everyone who provides their expertise to others. We love
        to work with{' '}
        <b>personal trainers, teachers, tutors, therapists and life coaches.</b>
        But if you’re none of these, and you think sessions would be useful to
        you, <b>let us know!</b>
      </Paragraph>
    </FluidWrapper>
  </IntroOuterWrapper>
);
