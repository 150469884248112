import { GlobalStyles } from 'components/GlobalStyles';
import { lightTheme } from 'lib/theme';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

export const Layout: React.FC = ({ children }) => (
  <ThemeProvider theme={lightTheme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);
