import { Feature } from 'components/Feature';
import { FeatureProps } from 'components/Feature/types';
import {
  FeaturesIphoneBottom,
  FeaturesIphoneTop,
  FeaturesList,
  FeaturesOuterWrapper,
  FeaturesWrapper,
} from 'components/Features/layout';
import { Subheading } from 'components/Subheading';
import * as React from 'react';

const features: FeatureProps[] = [
  {
    icon: require('images/track-sales.svg'),
    title: 'Track Sales',
    description:
      'If you sell packages of session up front, keep track of how many you’ve sold and to who!',
  },
  {
    icon: require('images/track-sessions-used.svg'),
    title: 'Track Sessions Used',
    description:
      'Ensure you always know how many session you have left with a client, by tracking sessions they’ve attended.',
  },
  {
    icon: require('images/clients-notifications.svg'),
    title: 'Clients Notifications',
    description:
      'Be transparent with your client and provide them with notificaitons when the balance changes, and even encorage them to buy more with reminders of low balances.',
  },
  {
    icon: require('images/handle-unpaid-sessions.svg'),
    title: 'Handle Unpaid Sessions',
    description:
      'If you provide sessions and accept payment later, keep track of whose paid to ensure you always get paid.',
  },
  {
    icon: require('images/insight.svg'),
    title: 'Insight',
    description: 'See all your customers in one place, and stats about them.',
  },
];

export const Features: React.FC = () => {
  const items = features.map((feature) => (
    <Feature
      key={feature.title}
      icon={feature.icon}
      title={feature.title}
      description={feature.description}
    />
  ));

  const half = Math.round(items.length / 2);

  return (
    <FeaturesOuterWrapper>
      <FeaturesWrapper>
        <Subheading>Features</Subheading>
        <FeaturesList>
          <FeaturesIphoneTop />
          {items.slice(0, half)}
          <FeaturesIphoneBottom />
          {items.slice(half, items.length)}
        </FeaturesList>
      </FeaturesWrapper>
    </FeaturesOuterWrapper>
  );
};
