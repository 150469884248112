import { FluidWrapper } from 'components/FluidWrapper';
import { Paragraph } from 'components/Paragraph';
import {
  RegisterButton,
  RegisterForm,
  RegisterOuterWrapper,
  RegisterSubheading,
} from 'components/Register/layout';
import { RegisterProps } from 'components/Register/types';
import * as React from 'react';

export const Register = React.forwardRef<HTMLDivElement, RegisterProps>(
  ({ children }, ref) => (
    <RegisterOuterWrapper ref={ref}>
      <RegisterForm
        method="post"
        action="https://getsessions.us1.list-manage.com/subscribe/post?u=b62c6dc4044352a3d8ccd1008&id=bbbf7cb2a7"
      >
        <FluidWrapper>
          <RegisterSubheading>Register</RegisterSubheading>
          <Paragraph>
            We’re not quite ready yet but if you want to let us know you’re
            interested, we’ll include you in our early release.
          </Paragraph>
          {children}
          <RegisterButton>Register</RegisterButton>
        </FluidWrapper>
      </RegisterForm>
    </RegisterOuterWrapper>
  ),
);
