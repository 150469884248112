import {
  ComingSoonFeatureIcon,
  ComingSoonFeatureTitle,
  ComingSoonFeatureWrapper,
} from 'components/ComingSoonFeature/layout';
import { ComingSoonFeatureProps } from 'components/ComingSoonFeature/types';
import * as React from 'react';

export const ComingSoonFeature: React.FC<ComingSoonFeatureProps> = ({
  icon,
  title,
}) => (
  <ComingSoonFeatureWrapper>
    <ComingSoonFeatureIcon src={(icon as unknown) as string} />
    <ComingSoonFeatureTitle>{title}</ComingSoonFeatureTitle>
  </ComingSoonFeatureWrapper>
);
