import { FluidWrapper } from 'components/FluidWrapper';
import { NavMenuProps } from 'components/Navigation/types';
import styled, { css } from 'styled-components';

const BREAKPOINT = 768;

export const NavOuterWrapper = styled.div(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${theme.colors.highlight};
    padding-top: ${theme.grid * 7}px;
    padding-bottom: ${theme.grid * 3}px;
  `,
);

export const NavWrapper = styled(FluidWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const NavMenu = styled.nav<NavMenuProps>(
  ({ shown, theme }) => css`
    @media screen and (max-width: ${BREAKPOINT}px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${theme.colors.overlay};
      pointer-events: ${shown === true ? 'initial' : 'none'};
      opacity: ${shown === true ? '1' : '0'};
      transform: scale(${shown === true ? '1' : '0.5'});
      transform-origin: 100% 0;
      transition: transform 0.175s, opacity 0.175s;
    }
  `,
);

export const NavLink = styled.a(
  ({ theme }) => css`
    font-size: ${theme.type.body.fontSize}px;
    line-height: ${theme.type.body.lineHeight}px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${theme.colors.overlay};
    margin: 0 ${theme.grid}px;

    @media screen and (max-width: ${BREAKPOINT}px) {
      font-size: ${theme.type.navLink.fontSize}px;
      line-height: ${theme.type.navLink.lineHeight}px;
      color: ${theme.colors.background};
      margin: ${theme.grid * 2.5}px ${theme.grid}px;

      &:first-of-type {
        margin-top: ${theme.grid * 4}px;
      }
    }

    &,
    & > * {
      text-decoration: none;
    }
  `,
);

export const OccludeOnDesktop = styled.div`
  @media screen and (min-width: ${BREAKPOINT + 1}px) {
    display: none;
  }
`;
