import { FluidWrapper } from 'components/FluidWrapper';
import { Paragraph } from 'components/Paragraph';
import {
  PricingButton,
  PricingOuterWrapper,
  PricingSubheading,
} from 'components/Pricing/layout';
import { PricingProps } from 'components/Pricing/types';
import * as React from 'react';

export const Pricing = React.forwardRef<HTMLDivElement, PricingProps>(
  ({ interestedAction }, ref) => (
    <PricingOuterWrapper id="pricing" ref={ref}>
      <FluidWrapper>
        <PricingSubheading>Pricing</PricingSubheading>
        <Paragraph>
          We want to include the community to find the fairest way to price
          Sessions, let us know you’re interested in being part of the decision.
        </Paragraph>
        <PricingButton onClick={interestedAction}>I’m interested</PricingButton>
      </FluidWrapper>
    </PricingOuterWrapper>
  ),
);
