import { HamburgerLineProps } from 'components/HamburgerCta/types';
import styled, { css } from 'styled-components';

const BREAKPOINT = 768;
const LINE_HEIGHT = 3;
const HIT_SLOP_GRID = 2;

export const HamburgerLink = styled.a(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    z-index: 2;
    align-items: center;
    justify-content: center;
    bottom: ${theme.grid * 2}px;
    right: ${theme.grid * 2.5}px;
    right: ${theme.grid * 3}px;
    width: ${theme.grid * (3 + HIT_SLOP_GRID)}px;
    height: ${theme.grid * (3 + HIT_SLOP_GRID)}px;

    @media screen and (min-width: ${BREAKPOINT + 1}px) {
      display: none;
    }
  `,
);

export const HamburgerLines = styled.span(
  ({ theme }) => css`
    position: relative;
    width: ${theme.grid * 3}px;
    height: ${theme.grid * 2 + 1}px;
  `,
);

export const TopLine = styled.span<HamburgerLineProps>(
  ({ theme, shown }) => css`
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: ${theme.grid * 3}px;
    height: 3px;
    transform: ${shown === true
      ? 'translateX(4px) rotate(45deg)'
      : 'rotate(0)'};
    transform-origin: 0 0;
    transition: transform 0.175s;
    background-color: ${theme.colors.copy};
    border-radius: 3px;
  `,
);

export const MiddleLine = styled.span<HamburgerLineProps>(
  ({ theme, shown }) => css`
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: ${theme.grid * 3}px;
    height: 3px;
    margin-top: ${-LINE_HEIGHT / 2}px;
    transform: ${shown === true ? 'scale(0)' : 'scale(1)'};
    transition: transform 0.175s;
    background-color: ${theme.colors.copy};
    border-radius: 3px;
  `,
);

export const BottomLine = styled.span<HamburgerLineProps>(
  ({ theme, shown }) => css`
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${theme.grid * 3}px;
    height: 3px;
    transform: ${shown === true
      ? 'translate(2px, 2px) rotate(-45deg)'
      : 'rotate(0)'};
    transform-origin: 0 0;
    transition: transform 0.175s;
    background-color: ${theme.colors.copy};
    border-radius: 3px;
  `,
);
