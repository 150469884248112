import styled, { css } from 'styled-components';

export const Subheading = styled.h3(
  ({ theme }) => css`
    font-size: ${theme.type.subheading.fontSize}px;
    line-height: ${theme.type.subheading.lineHeight}px;
    color: ${theme.colors.copy};
    text-transform: uppercase;
    margin: 0;
  `,
);
