import {
  FeatureIcon,
  FeatureTitle,
  FeatureWrapper,
} from 'components/Feature/layout';
import { FeatureProps } from 'components/Feature/types';
import { Paragraph } from 'components/Paragraph';
import * as React from 'react';

export const Feature: React.FC<FeatureProps> = ({
  icon,
  title,
  description,
}) => (
  <FeatureWrapper>
    <FeatureIcon src={(icon as unknown) as string} />
    <FeatureTitle>{title}</FeatureTitle>
    <Paragraph>{description}</Paragraph>
  </FeatureWrapper>
);
