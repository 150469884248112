import { FluidWrapper } from 'components/FluidWrapper';
import styled, { css } from 'styled-components';

export const FeaturesOuterWrapper = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.accent};
    padding-top: ${theme.grid * 3}px;
    padding-bottom: ${theme.grid * 3}px;
    overflow: hidden;
  `,
);

export const FeaturesWrapper = styled(FluidWrapper)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const FeaturesList = styled.section.attrs({
  role: 'list',
})`
  margin: 0;
  padding: 0;
`;

export const FeaturesIphoneTop = styled.img.attrs({
  src: require('images/features-iphone-top.png'),
})(
  ({ theme }) => css`
    width: 70%;
    max-width: ${theme.grid * 40}px;
    float: right;
    margin-right: -${theme.grid * 10}px;
    margin-left: ${theme.grid * 1}px;
    margin-bottom: ${theme.grid * 1}px;
  `,
);

export const FeaturesIphoneBottom = styled.img.attrs({
  src: require('images/features-iphone-bottom.png'),
})(
  ({ theme }) => css`
    width: 50%;
    max-width: ${theme.grid * 30}px;
    float: left;
    margin-left: -${theme.grid * 4}px;
    margin-top: ${theme.grid * 2}px;
    margin-right: ${theme.grid * 2}px;

    @media screen and (min-width: 560px) {
      display: none;
    }
  `,
);
