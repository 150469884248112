import { Button } from 'components/Button';
import { Subheading } from 'components/Subheading';
import styled, { css } from 'styled-components';

export const PricingOuterWrapper = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.accent};
    padding-top: ${theme.grid * 3}px;
    padding-bottom: ${theme.grid * 3}px;
  `,
);

export const PricingSubheading = styled(Subheading)(
  ({ theme }) => css`
    color: ${theme.colors.background};
    margin-bottom: ${theme.grid * 2}px;
  `,
);

export const PricingButton = styled(Button)(
  ({ theme }) => css`
    margin-top: ${theme.grid * 3}px;
  `,
);
