import { RegisterInputContainer } from 'components/RegisterInput/layout';
import { TextInput } from 'components/TextInput';
import * as React from 'react';

export const RegisterInput = React.forwardRef<HTMLInputElement>((_, ref) => (
  <RegisterInputContainer>
    <TextInput
      type="email"
      placeholder="Email Address"
      ref={ref}
      name="EMAIL"
    />
  </RegisterInputContainer>
));
