import { CopyrightOuterWrapper, SmallPrint } from 'components/Copyright/layout';
import { CopyrightProps } from 'components/Copyright/types';
import { FluidWrapper } from 'components/FluidWrapper/layout';
import { Logo } from 'components/Logo';
import * as React from 'react';

export const Copyright: React.FC<CopyrightProps> = ({ date = new Date() }) => (
  <CopyrightOuterWrapper>
    <FluidWrapper>
      <Logo gridWidth={11.5} />
      <SmallPrint>
        Copyright © {date.getFullYear()} Deliberate Software Ltd. All rights
        reserved.
      </SmallPrint>
    </FluidWrapper>
  </CopyrightOuterWrapper>
);
