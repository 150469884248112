import { LogoProps } from 'components/Logo/types';
import styled, { css } from 'styled-components';

export const Logo = styled.img.attrs<LogoProps>(({ inverse }) => ({
  src:
    inverse === true
      ? require('images/logo-inverse.svg')
      : require('images/logo.svg'),
}))<LogoProps>(
  ({ gridWidth = 10, theme }) =>
    css`
      width: ${gridWidth * theme.grid}px;
    `,
);
