import {
  ComingSoonList,
  ComingSoonOuterWrapper,
} from 'components/ComingSoon/layout';
import { ComingSoonFeature } from 'components/ComingSoonFeature';
import { ComingSoonFeatureProps } from 'components/ComingSoonFeature/types';
import { FluidWrapper } from 'components/FluidWrapper';
import { Subheading } from 'components/Subheading';
import * as React from 'react';

const comingSoon: ComingSoonFeatureProps[] = [
  { icon: require('images/calendar.svg'), title: 'Calendar' },
  { icon: require('images/online-booking.svg'), title: 'Online Booking' },
  { icon: require('images/online-payment.svg'), title: 'Online Payment' },
  {
    icon: require('images/business-health-check.svg'),
    title: 'Business Health Check',
  },
  {
    icon: require('images/branded-notifications-and-crm.svg'),
    title: 'Branded Notifications & CRM',
  },
];

export const ComingSoon: React.FC = () => (
  <ComingSoonOuterWrapper>
    <FluidWrapper>
      <Subheading>Coming Soon</Subheading>
      <ComingSoonList>
        {comingSoon.map((comingSoonFeature) => (
          <ComingSoonFeature
            key={comingSoonFeature.title}
            icon={comingSoonFeature.icon}
            title={comingSoonFeature.title}
          />
        ))}
      </ComingSoonList>
    </FluidWrapper>
  </ComingSoonOuterWrapper>
);
