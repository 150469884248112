import styled, { css } from 'styled-components';

export const FeatureWrapper = styled.div.attrs({
  role: 'listitem',
})(
  ({ theme }) => css`
    padding-top: ${theme.grid * 2}px;
  `,
);

export const FeatureIcon = styled.img(
  ({ theme }) => css`
    width: auto;
    height: ${theme.grid * 5}px;
    margin-bottom: ${theme.grid}px;
  `,
);

export const FeatureTitle = styled.h4(
  ({ theme }) => css`
    font-size: ${theme.type.subheading.fontSize}px;
    line-height: ${theme.type.subheading.fontSize}px;
    color: ${theme.colors.background};
    margin-top: 0;
    margin-bottom: ${theme.grid / 2}px;
  `,
);
