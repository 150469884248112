import { HamburgerCta } from 'components/HamburgerCta/HamburgerCta';
import { Logo } from 'components/Logo';
import {
  NavLink,
  NavMenu,
  NavOuterWrapper,
  NavWrapper,
  OccludeOnDesktop,
} from 'components/Navigation/layout';
import { NavigationProps } from 'components/Navigation/types';
import * as React from 'react';

export const toggleShownCallback = (
  shown: boolean,
  setShown: (shown: boolean) => void,
) => () => {
  setShown(!shown);
};

export const withHideCallback = (setShown: (shown: boolean) => void) => (
  action: (e: React.MouseEvent) => void,
) => (e: React.MouseEvent) => {
  setShown(false);
  action(e);
};

export const Navigation: React.FC<NavigationProps> = ({
  links,
  shown: shownProp = false,
}) => {
  const [shown, setShown] = React.useState(shownProp);

  const toggleShown = React.useCallback(toggleShownCallback(shown, setShown), [
    shown,
    setShown,
  ]);

  const withHide = React.useCallback(withHideCallback(setShown), [setShown]);

  return (
    <NavOuterWrapper>
      <NavWrapper>
        <Logo gridWidth={22} />
        <HamburgerCta shown={shown} onPress={toggleShown}>
          Hamburger
        </HamburgerCta>
        <NavMenu shown={shown}>
          <OccludeOnDesktop>
            <Logo gridWidth={22} inverse={true} />
          </OccludeOnDesktop>
          {links.map((link) => (
            <NavLink
              key={link.title}
              href={link.anchor}
              onClick={withHide(link.action)}
            >
              {link.title}
            </NavLink>
          ))}
        </NavMenu>
      </NavWrapper>
    </NavOuterWrapper>
  );
};
