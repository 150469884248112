import { Subheading } from 'components/Subheading';
import styled, { css } from 'styled-components';

export const IntroOuterWrapper = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.background};
    padding-top: ${theme.grid * 3}px;
    padding-bottom: ${theme.grid * 3}px;
  `,
);

export const Heading = styled.h1(
  ({ theme }) => css`
    color: ${theme.colors.overlay};
    font-size: ${theme.type.heading.fontSize}px;
    line-height: ${theme.type.heading.lineHeight}px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: ${theme.grid * 3}px;
  `,
);

export const IntroSubheading = styled(Subheading).attrs({
  role: 'heading',
  'aria-level': 2,
})(
  ({ theme }) => css`
    margin-bottom: ${theme.grid * 2}px;
  `,
);
