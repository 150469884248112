import styled, { css } from 'styled-components';

export const TextInput = styled.input(
  ({ theme }) => css`
    appearance: none;
    width: 100%;
    font-size: ${theme.type.input.fontSize}px;
    line-height: ${theme.type.input.lineHeight}px;
    padding: ${theme.grid}px;
    border-radius: ${theme.grid}px;
    background-color: ${theme.colors.background};
    color: ${theme.colors.copy};
    border: none;
    outline: 0;
    transition: box-shadow 0.125s;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:focus {
      box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    }
  `,
);
