import {
  BottomLine,
  HamburgerLines,
  HamburgerLink,
  MiddleLine,
  TopLine,
} from 'components/HamburgerCta/layout';
import { HamburgerCtaProps } from 'components/HamburgerCta/types';
import * as React from 'react';

export const onPressCallback = (onPress: HamburgerCtaProps['onPress']) => (
  e?: React.MouseEvent,
) => {
  e?.preventDefault();
  onPress();
};

export const HamburgerCta: React.FC<HamburgerCtaProps> = ({
  shown,
  onPress: onPressAction,
}) => {
  const onPress = React.useCallback(onPressCallback(onPressAction), [
    onPressAction,
  ]);

  return (
    <HamburgerLink href="#menu" onClick={onPress}>
      <HamburgerLines>
        <TopLine shown={shown} />
        <MiddleLine shown={shown} />
        <BottomLine shown={shown} />
      </HamburgerLines>
    </HamburgerLink>
  );
};
