import styled, { css } from 'styled-components';

export const ComingSoonOuterWrapper = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.background};
    padding-top: ${theme.grid * 3}px;
    padding-bottom: ${theme.grid * 3}px;
  `,
);

export const ComingSoonList = styled.section.attrs({
  role: 'list',
})`
  overflow: hidden;
`;
