import styled, { css } from 'styled-components';

export const ComingSoonFeatureWrapper = styled.div.attrs({
  role: 'listitem',
})(
  ({ theme }) => css`
    padding-top: ${theme.grid * 2}px;
    float: left;
    width: 33.333%;

    @media screen and (max-width: 360px) {
      width: 50%;
    }

    @media screen and (max-width: 280px) {
      width: 100%;
    }
  `,
);

export const ComingSoonFeatureIcon = styled.img(
  ({ theme }) => css`
    width: auto;
    height: ${theme.grid * 5}px;
    margin-bottom: ${theme.grid}px;
  `,
);

export const ComingSoonFeatureTitle = styled.h4(
  ({ theme }) => css`
    font-size: ${theme.type.subheading.fontSize}px;
    line-height: ${theme.type.subheading.fontSize}px;
    color: ${theme.colors.copy};
    margin-top: 0;
    margin-bottom: ${theme.grid / 2}px;
  `,
);
